"use client" // Error components must be Client Components

import { Box, Button, Text } from "@chakra-ui/react"
import { useEffect } from "react"

type ErrorProps = {
  error: Error
  reset: () => void
}
export default function Error({ error, reset }: ErrorProps) {
  useEffect(() => {
    console.error(error)
  }, [error])

  return (
    <Box m="4rem">
      <Text fontSize={"5xl"} color="white">
        Something went wrong with the explore | home page!
      </Text>
      <Button onClick={reset}>Try again</Button>
    </Box>
  )
}
